.category_wrap {
    display: flex;
    gap: 50px;
    margin-left: 16px;
    margin-top: 30px;
}

.single_list {
    max-width: 350px;
    width: 100%;
    border: 3px solid #EBEFF3;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    min-height: 86vh;
    position: relative;
}

.category_list_title {
    font-size: 20px;
    font-weight: 500;
    padding: 16px;
    text-align: center;
    border-bottom: 2px solid #666;
}

.category_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 2px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
}

.category_item.active {
    background-color: #EBEFF3;
}

.category_item .image_action_button {
    padding: 5px;
    padding-top: 6px;
}

.category_item .image_action_button img {
    width: 20px;
    height: 20px;
}

.no_data {
    text-align: center;
    padding: 15px;
}