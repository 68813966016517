.navigation {
    min-height: calc(100vh - 20px);
    max-width: 300px;
    width: 100%;
    background: #fff;
    padding: 16px;
    border-radius: 10px;

}

.logo {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    background-color: #EBEFF3;
    border-radius: 5px;
    padding: 10px;
}

.menu {
    list-style: none;
    margin-top: 50px;
    min-height: calc(100vh - 225px);
    /* display: flex;
    flex-direction: column;
    gap: 15px; */
}

.menu_item {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #EBEFF3;
    color: #000;
    text-decoration: none;
    border-radius: 8px;
    padding: 12px 8px;
    transition: all .3s ease-in-out;
    cursor: pointer;
    border-bottom: 4px solid #EBEFF3;
    margin-top: 15px;
}

.menu_icon {
    width: 20px;
}

.menu_label {
    font-size: 16px;
    font-weight: 500;
}

.menu_item:hover {
    background-color: #c1cdd9;
    border-bottom: 4px solid #c1cdd9;
}


.menu_item.active {
    border-bottom: 4px solid #c1cdd9;
}

.loguot {
    text-align: center;
}

.loguot .menu_item {
    /* display: inline-flex;
    padding: 12px 20px; */
    justify-content: center;
}