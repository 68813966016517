@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.app {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #EBEFF3;
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: stretch;
}

.content_wrapper {
  overflow: hidden;
  min-height: calc(100vh - 20px);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

/* 
.breadcrumb_page_title {
  font-size: 24px;
  font-weight: 700;
}

.breadcrumb {
  padding: 16px;
  background-color: #fff;
} */

.content_section {
  background-color: #fff;
  /* margin-top: 10px; */
  padding-bottom: 16px;
  position: relative;
  height: calc(100vh - 20px);
}

.content_section_header {
  padding: 16px;
  border-bottom: 3px solid #EBEFF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_section_title {
  font-size: 20px;
  font-weight: 500;
}

.content_section_actions {
  display: flex;
  gap: 10px;
}

/* ======= Button css ======= */
.button_add {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #000;
}

.button_add span {
  font-size: 16px;
  font-weight: 500;
}

.button_primary {
  background-color: #E3F5FF;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button_primary:hover {
  background-color: #c1cdd9;
}

/* .table_container {
  overflow: auto;
  max-width: fit-content;
}

.table_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #EBEFF3;
  flex-wrap: wrap;
}

.table_cell {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-right: 1px solid #EBEFF3;
  max-width: 320px;
  overflow: hidden;
} */

.cell_image_box {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 2px solid #c1cdd9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell_image_box img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}


/* ======= From css ======= */
.input_form {}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.input_label {
  font-size: 16px;
}

.input_box {
  font-size: 16px;
  border: 1px solid #999;
  padding: 8px 10px;
  border-radius: 5px;
}

.input_box.inactive {
  background-color: rgba(0, 0, 0, .3);
  cursor: not-allowed;
}

.form_status {
  margin-bottom: 15px;
  text-align: center;
  padding: 5px;
  border: 1px solid #999;
  font-weight: 500;
}

.loading_message {
  background-color: rgb(255, 255, 150);
}

.error_message {
  background-color: rgb(255, 150, 150);
}

.success_message {
  background-color: rgb(150, 255, 150);
}

.error_message.active {
  display: block;
}

.button_submit {
  font-weight: 500;
  font-size: 16px;
  padding: 8px 15px;
  border: 1px solid #666;
}

.input_group_submit {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
}


/* ======= Modal css ======= */

.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
}

.modal_wrap {
  position: absolute;
  max-width: 550px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
}

.modal_title {
  font-size: 20px;
  font-weight: 500;
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #666;
}

.modal_body {
  padding: 16px;
}

.content_row {
  display: grid;
  border: 1px solid #666;
  border-bottom: none;
  grid-template-columns: 1fr 2fr;
}

.content_row:last-child {
  border-bottom: 1px solid #666;
}

.content_cell {
  border-right: 1px solid #666;
  padding: 5px;
}

.content_cell:last-child {
  border-right: none;
}

.modal_action {
  margin: 20px auto;
  text-align: center;
}

/* .modal_action .button_primary {
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #666;
} */

.cell_image_list {
  display: flex;
  gap: 5px;
}

.cell_image_box {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 2px solid #c1cdd9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cell_image_link {
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 17px;
  background-color: rgba(255, 255, 255, .5);
}

.cell_image_link img {
  width: 25px;
  height: 25px;
}

.cell_image_box:hover .cell_image_link {
  visibility: visible;
}

.cell_image_box>img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.no_data_text {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  background-color: rgb(251, 210, 210);
  text-align: center;
}

/* ======= Table css ======= */
.table {
  border-collapse: collapse;
}

.table th {
  border-right: 1px solid #fff;
  background-color: #ebeff3;
  color: #000;
  text-align: left;
  padding: 10px 16px;
}

.table td {
  border: 1px solid #EBEFF3;
  padding: 10px 16px;
}

.table_action {
  display: flex;
  gap: 5px;
}

.table_head tr,
.table_body tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* .manage_pdf .table_head tr th:nth-child(1),
.manage_pdf .table_head tr th:nth-child(2),
.manage_pdf .table_head tr th:last-child,
.manage_pdf .table_body tr td:nth-child(1),
.manage_pdf .table_body tr td:last-child,
.manage_pdf .table_body tr td:nth-child(2) {
  width: 130px;
} */

.table_body {
  display: block;
  height: calc(100vh - 140px);
  overflow-y: scroll;
  position: relative;
}

.table_body tr td {
  overflow: hidden;
}

.table_body tr.no_data td {
  border: none;
  padding: 0;
}

.issueType {
  background-color: #E3F5FF;
  border: none;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  margin: 4px;
  font-size: 13px;
}