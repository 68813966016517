.image_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 16px;
    max-height: calc(100vh - 100px);
    overflow: scroll;
}

.image_item {
    width: 145px;
    height: 145px;
    overflow: hidden;
    border: 2px solid #c1cdd9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.image_item img {
    width: 145px;
    height: 145px;
    object-fit: contain;
}

.image_action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgba(0, 0, 0, .3);
}

.image_item:hover .image_action {
    display: flex;
}

.image_action .image_action_button {
    padding: 5px;
    padding-top: 6px;
}

.image_action .image_action_button img {
    width: 20px;
    height: 20px;
}

.imageModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, .3);
}

.imageModal_Wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imageModal_imgbox {
    position: relative;
    max-width: 80vh;
    max-height: 80vh;
    background-color: #fff;
}

.closeIcon {
    position: absolute;
    top: -50px;
    right: -50px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.closeIcon img {
    width: 36px;
}

.imageModal_image {
    max-width: 80vh;
    max-height: 80vh;
    object-fit: contain;
    padding: 3px;
    padding-bottom: 0;
}