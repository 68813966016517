.error_page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error_page_title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}

.error_page_text {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

.error_page_message {
    font-size: 18px;
    font-weight: 500;
}