.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* min-height: 100vh; */
    position: absolute;
    height: 100%;
    width: 100%;
}

.loaders .loader {
    position: relative;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 100px;
}

.loaders .loader::before {
    counter-increment: list;
    content: counter(list, decimal-leading-zero);
    position: absolute;
    top: -20px;
    left: -25px;
    font-size: 16px;
}

.loader1 span {
    display: block;
    width: 70px;
    height: 70px;
    border: 3px solid;
    border-radius: 50%;
    border-color: transparent #000 #000;
    -webkit-animation: cssload-spin 690ms infinite linear;
    animation: cssload-spin 690ms infinite linear;
}

@-webkit-keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}