.login_page {
    background-color: rgba(0, 0, 0, .5);
    position: relative;
    width: 100vw;
    height: 100vh;
}

.login_container {
    position: absolute;
    max-width: 300px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
}

.login_title_text {
    text-align: center;
    margin-bottom: 20px;
}